.ckeditor p {
  margin-bottom: 0px !important;
}

.link-nav:hover > :first-child,
.link-nav-active > :first-child {
  /* border: 1px solid; */
  background-color: #fff;
  padding: 8px;
  border-radius: 50%;
  /* box-shadow: 0 0 5px rgba(255, 255, 255, 0.4); */
}
.link-nav:hover,
.link-nav-active {
  background-color: black;
  border-radius: 2rem;
  box-shadow: 0 0 5px rgba(0, 255, 255, 0.8);
}
.link-nav > :first-child {
  padding: 8px;
}
/* ImageUploader.css */

.custom-image-uploader {
  /* Add custom styles for the container div */
}

.custom-upload-button {
  /* Add custom styles for the upload button */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  border: 2px dashed #1890ff;
  border-radius: 5px;
  padding: 16px;
  cursor: pointer;
  transition: border 0.3s ease;

  &:hover {
    border-color: #40a9ff;
  }
}

.custom-upload-text {
  /* Add custom styles for the upload button text */
  margin-top: 8px;
}

.uploaded-image {
  /* Add custom styles for the uploaded image */
  max-width: 100%;
  max-height: 200px;
  object-fit: cover;
}

.uploaded-video {
  /* Add custom styles for the uploaded video */
  max-width: 100%;
  max-height: 300px;
  object-fit: cover;
}
